import React from 'react'
import './Slide.css'

function Slide() {
    return (
        <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
            <div className="carousel-indicators ci">
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4" aria-label="Slide 5"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item cci active" data-bs-interval="10000">
                    <img src="/c1.svg" className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item cci" data-bs-interval="2000">
                    <img src="/c2.svg" className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item cci">
                    <img src="/c3.svg" className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item cci">
                    <img src="/c4.svg" className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item cci">
                    <img src="/c5.svg" className="d-block w-100" alt="..." />
                </div>
            </div>
        </div>
    )
}

export default Slide