import React from 'react'
import './Hero.css'

function Hero() {
    return (
        <>
            <section className="banner part-one d-none d-xl-flex p-5">
                <div className='one-text'>
                    <h1>The <span className='span'>1st</span> Identity Governance and Administration (IGA) Solution Built for SMBs is finally here!</h1>
                    <br />
                    <p>Small and medium businesses are the most vulnerable to cyberattacks because attackers often see them as low-hanging fruit. Learn how PrimeiD can help.</p>
                    <br />
                    <a className="prime-id-btn" href="/#form">TRY PRIMEID NOW</a>
                    <a className="video-btn" href="#video">LEARN MORE <img className="video-btn-image" src="/video-btn.png" alt="Video play button icon"></img></a>
                </div>
                <div className='one-img' style={{ marginTop: '35px' }}>
                    <img src="/hero.svg" alt="Desktop banner" />
                </div>
            </section>

            <section className='banner one-mobile d-block d-xl-none'>
                <div className='text-mobile'>
                    <h1>The <span className='span'>1st</span> Identity Governance and Administration (IGA) Solution Built for SMBs is finally here!</h1>
                    <br />
                    <p>Small and medium businesses are the most vulnerable to cyberattacks because attackers often see them as low-hanging fruit. Learn how PrimeiD can help.</p>
                    <br />
                    <div className='btn-mobile'>
                        <a className="prime-id-btn" href="/#form">TRY PRIMEID NOW</a>
                        <a className="video-btn" href="#video">LEARN MORE <img className="video-btn-image" src="/video-btn.png" alt="Video play button icon"></img></a>
                    </div>
                </div>
                <div className='img-mobile'>
                    <img src="/mobile2.png" alt="Mobile banner" />
                </div>
            </section>
        </>
    )
}

export default Hero