import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Solution from './components/Solution';
import Three from './components/Three';
import Form from './components/Form';
import Four from './components/Four';
import Footer from './components/Footer';

// import Button from './components/Button';

function App() {
  return (
    <div className="App">
      <section className="header">
        <div className="container-fluid">
          <Header />
        </div>
      </section>
      <section className="banner">
        <div className="container">
          <Hero />
        </div>
      </section>
      <section className="solution">
        <div className="container">
          <Solution />
        </div>
      </section>
      <section className="devices">
        <div className="container">
          <Three />
        </div>
      </section>
      <section className="video" id="video">
        <div className="container">
          <div className="content-wrapper">
            <h2 className="title">Everything you need to know and more about PrimeID!</h2>
            <div className="description sub-heading">Watch this video and let us know what you think by filling out our contact form below.</div>
            <iframe className="video-frame" title="PrimeID Video" src="https://drive.google.com/file/d/1mRRSUrY7oNN-57oSz6dCsT0J29BEIu8K/preview" frameborder="0"></iframe>
          </div>
        </div>
      </section>
      <section className="form" id="form">
        <div className="container">
          <Form />
        </div>
      </section>
      <section className="image-content">
        <div className="container">
          <Four />
        </div>
      </section>
      <section className="footer">
        <div className="container">
        <Footer />
        </div>
      </section>
    </div>
  );
}

export default App;
