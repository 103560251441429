import React from 'react'
import './Form.css'

function Form() {

    function submit(e) {
        let report = document.getElementById('index')
        e.preventDefault();
        const data = new FormData(report);
        const action =
            'https://script.google.com/macros/s/AKfycbwNlKoZgtfwpflP0RjLWM3H19zU8_ujgLfp1ph3eVUHrXWwiuLJLMIx2ynnROYZJTYg/exec';

        fetch(action, {
            method: 'POST',
            body: data,
        })
            .then(() => {
                report.reset();
                alert('Thanks for submitting. Your respose has been saved.')
            })
    }

    return (
        <div id='form'>
            <div className='form'>
                <h1>See PrimeiD in action</h1>
                <p className="sub-heading">Learn how PrimeiD's identity governance solution helps SMBs enable their workforce by securing digital identities.</p>
            </div>
            <div className='form-form mb-5'>
                <form autoComplete='off' onSubmit={submit} id='index'>
                    <div className='mb-1'>
                        <label className='form-label' htmlFor="name">Full name</label> <br />
                        <input type="text" className='form-control' required name="Name" id="name" placeholder='Enter full name' /> <br />
                    </div>
                    <div className='mb-1'>
                        <label className='form-label' htmlFor="company">Company</label> <br />
                        <input type="text" className='form-control' required name="Company" id="company" placeholder='Enter company name' /> <br />
                    </div>
                    <div className='mb-1'>
                        <label className='form-label' htmlFor="position">Position</label> <br />
                        <input type="text" className='form-control' required name="Position" id="position" placeholder='Enter position' /> <br />
                    </div>
                    <div className='mb-1'>
                        <label className='form-label' htmlFor="mobile">Mobile number</label> <br />
                        <input type="text" className='form-control' required name="Phone" id="mobile" placeholder='Enter mobile number' /> <br />
                    </div>
                    <div className='mb-1'>
                        <label className='form-label' htmlFor="email">Email</label> <br />
                        <input type="email" className='form-control' required name="Email" id="address" placeholder='Enter email address' /> <br />
                    </div>
                    <div className='btn-form' style={{display: 'flex', justifyContent: 'center'}}>
                        <button type="submit" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>SUBMIT</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Form