import React from 'react'
import './Three.css'

function Three() {
  return (
    <section className='mb-5'>
        <div className='three'>
            <h1>Efficiently manage your user identities and access across your organization</h1>
            <br />
            <p className="sub-heading">PrimeiD also helps implement the necessary controls to prevent inappropriate or risky access. Keeping your company safe and confident.</p>
        </div>
        <div className="three-next">
            <img src="/different-devices.png" alt="Devices" />
        </div>
    </section>
  )
}

export default Three