import React, { useState } from 'react'
import './Four.css'
import Slide from './Slide'
import './Solution.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css';
// import Slides from './Slides';

function Four() {
  SwiperCore.use(Autoplay)
  const [show, setshow] = useState(false)
  return (
    <>
      <section className='mb-5'>
        <div className='four wrong'>
          <h1>What's wrong with the world right now</h1>
          <div className='d-sm-flex justify-content between pt-3'>
            <p className='px-sm-2'>Because of digital transformation small and medium businesses are experiencing explosion of data and they don't know how to manage and protect them anymore. Not to mention, integrating a lot of applications and solutions without proper governance and lack of expertise.</p>
            <p className='px-sm-2'>On a day to day basis,  companies deal with big data that are siloed, unstructured, and poor in quality. To add, duplicated records that cannot be accessed centrally. This leaves them with a lot of vulnerabilities and give cybercriminals more ways to hack their organization and this is what we want to solve.</p>
          </div>
        </div>

        <div className='d-none d-md-block four-one'>
          <div className='row py-5 text-center world-icons'>
            <div className="col-1"></div>
            <div className='col-2'>
              <img src="/six.svg" alt="" />
              <p className='py-2 text-center'>Poor quality of data</p>
            </div>
            <div className='col-2'>
              <img src="/seven.svg" alt="" />
              <p className='py-2 text-center'>Issues in scaling</p>
            </div>
            <div className='col-2'>
              <img src="/eight.svg" alt="" />
              <p className='py-2 text-center'>Incorrect Integration</p>
            </div>
            <div className='col-2'>
              <img src="/nine.svg" alt="" />
              <p className='py-2 text-center'>Real-time data</p>
            </div>
            <div className='col-2'>
              <img src="/ten.svg" alt="" />
              <p className='py-2 text-center'>Lack of expertise</p>
            </div>
            <div className="col-1"></div>
          </div>
        </div>

        <div className='d-none d-md-block four-one'>
          <div className='row py-2 text-center world-icons'>
            <div className="col-1"></div>
            <div className='col-2'>
              <img src="/eleven.svg" alt="" />
              <p className='py-2 text-center'>Wide technologies' variety</p>
            </div>
            <div className='col-2'>
              <img src="/tw.svg" alt="" />
              <p className='py-2 text-center'>Organizational resistance</p>
            </div>
            <div className='col-2'>
              <img src="/th.svg" alt="" />
              <p className='py-2 text-center'>Data verification</p>
            </div>
            <div className='col-2'>
              <img src="/fo.svg" alt="" />
              <p className='py-2 text-center'>Heavy expenses</p>
            </div>
            <div className='col-2'>
              <img src="/fif.svg" alt="" />
              <p className='py-2 text-center'>Security and privacy</p>
            </div>
            <div className="col-1"></div>
          </div>
        </div>

        {!show && <div className='d-md-none d-sm-block d-none'>
          <div className='row py-5 four-one world-icons'>
            <div className="col-sm-1"></div>
            <div className='col-3 col-sm-2'>
              <img src="/six.svg" alt="" />
              <p className='py-2 text-center change'>Poor quality of data</p>
            </div>
            <div className="col-1 col-sm-2"></div>
            <div className='col-3 col-sm-2'>
              <img src="/seven.svg" alt="" />
              <p className='py-2 text-center change'>Issues in scaling</p>
            </div>
            <div className="col-1 col-sm-2"></div>
            <div className='col-3 col-sm-2'>
              <img src="/eight.svg" alt="" />
              <p className='py-2 text-center change'>Incorrect Integration</p>
            </div>
            <div className="col-sm-1"></div>
          </div>

          <div className="row four-one world-icons">
            <div className="col-1"></div>
            <div className='col-2'>
              <img src="/nine.svg" alt="" />
              <p className='py-2 text-center change real'>Real-time data</p>
            </div>
            <div className="col-2"></div>
            <div className='col-2'>
              <img src="/ten.svg" alt="" />
              <p className='py-2 text-center change'>Lack of expertise</p>
            </div>
            <div className="col-2"></div>
            <div className="col-2 bg" onClick={() => setshow(true)}><p>+5 more</p></div>
            <div className="col-1"></div>
          </div>
        </div>}

        {show && <div className='d-md-none d-sm-block d-none'>
          <div className='row py-5 four-one world-icons'>
            <div className="col-sm-1"></div>
            <div className='col-3 col-sm-2'>
              <img src="/eleven.svg" alt="" />
              <p className='py-2'>Wide technologies' variety</p>
            </div>
            <div className="col-1 col-sm-2"></div>
            <div className='col-3 col-sm-2'>
              <img src="/tw.svg" alt="" />
              <p className='py-2'>Organizational resistance</p>
            </div>
            <div className="col-1 col-sm-2"></div>
            <div className='col-3 col-sm-2'>
              <img src="/th.svg" alt="" />
              <p className='py-2'>Data verification</p>
            </div>
            <div className="col-sm-1"></div>
          </div>

          <div className="row four-one world-icons">
            <div className="col-1"></div>
            <div className='col-2'>
              <img src="/fo.svg" alt="" />
              <p className='py-2'>Heavy expenses</p>
            </div>
            <div className="col-2"></div>
            <div className='col-2'>
              <img src="/fif.svg" alt="" />
              <p className='py-2'>Security and privacy</p>
            </div>
            <div className="col-2"></div>
            <div className="col-2 bg" onClick={() => setshow(false)}><img src="back.svg" alt="" /> &nbsp; <p className='pt-3'>Go back</p></div>
            <div className="col-1"></div>
          </div>
        </div>}

        {!show && <div className='d-sm-none d-block'>
          <div className='row pt-5 four-one world-icons'>
            <div className="col-1" style={{ width: '6%' }}></div>
            <div className='col-2'>
              <img src="/six.svg" width={80} alt="" />
            </div>
            <div className="col-2"></div>
            <div className='col-2'>
              <img src="/seven.svg" width={80} alt="" />
            </div>
            <div className="col-2"></div>
            <div className='col-2'>
              <img src="/eight.svg" width={80} alt="" />
            </div>
            <div className="col-1"></div>
          </div>

          <div className='row four-one world-icons'>
            <div className="col-1" style={{ width: '6%' }}></div>
            <div className='col-3'>
              <p className='py-2 text-center'>Poor quality of data</p>
            </div>
            <div className="col-1"></div>
            <div className='col-3'>
              <p className='py-2 text-center'>Issues in scaling</p>
            </div>
            <div className="col-1"></div>
            <div className='col-3'>
              <p className='py-2 text-'>Incorrect Integration</p>
            </div>
            <div className="col-1"></div>
          </div>

          <div className="row four-one world-icons">
            <div className="col-1" style={{ width: '6%' }}></div>
            <div className='col-2'>
              <img src="/nine.svg" width={80} alt="" />
            </div>
            <div className="col-2"></div>
            <div className='col-2'>
              <img src="/ten.svg" width={80} alt="" />
            </div>
            <div className="col-2"></div>
            <div className="col-2 bg" style={{ height: '80px', width: '80px' }} onClick={() => setshow(true)}><p>+5 more</p></div>
            <div className="col-1"></div>
          </div>

          <div className="row four-one world-icons">
            <div className="col-1" style={{ width: '6%' }}></div>
            <div className='col-3'>
              <p className='py-2 text-center'>Real-time data</p>
            </div>
            <div className="col-1"></div>
            <div className='col-3'>
              <p className='py-2 text-center'>Lack of expertise</p>
            </div>
            <div className="col-1"></div>
            <div className="col-3"></div>
            <div className="col-1"></div>
          </div>
        </div>}

        {show && <div className='d-sm-none d-block'>
          <div className='row pt-5 four-one world-icons'>
            <div className="col-1" style={{ width: '6%' }}></div>
            <div className='col-2'>
              <img src="/eleven.svg" width={80} alt="" />
            </div>
            <div className="col-2"></div>
            <div className='col-2'>
              <img src="/tw.svg" width={80} alt="" />
            </div>
            <div className="col-2"></div>
            <div className='col-2'>
              <img src="/th.svg" width={80} alt="" />
            </div>
            <div className="col-1"></div>
          </div>

          <div className='row four-one world-icons'>
            <div className="col-1" style={{ width: '6%' }}></div>
            <div className='col-3'>
              <p className='py-2'>Wide technologies' variety</p>
            </div>
            <div className="col-1"></div>
            <div className='col-3'>
              <p className='py-2'>Organizational resistance</p>
            </div>
            <div className="col-1"></div>
            <div className='col-3'>
              <p className='py-2'>Data verification</p>
            </div>
            <div className="col-1"></div>
          </div>

          <div className="row four-one world-icons">
            <div className="col-1" style={{ width: '6%' }}></div>
            <div className='col-2'>
              <img src="/fo.svg" width={80} alt="" />
            </div>
            <div className="col-2"></div>
            <div className='col-2'>
              <img src="/fif.svg" width={80} alt="" />
            </div>
            <div className="col-2"></div>
            <div className="col-2 bg" style={{ height: '80px', width: '80px' }} onClick={() => setshow(false)}><img src="back.svg" alt="" /> &nbsp; <p className='pt-3'>Go back</p></div>
            <div className="col-1"></div>
          </div>

          <div className="row four-one world-icons">
            <div className="col-1" style={{ width: '6%' }}></div>
            <div className='col-3'>
              <p className='py-2 text-center'>Real-time data</p>
            </div>
            <div className="col-1"></div>
            <div className='col-3'>
              <p className='py-2 text-center'>Lack of expertise</p>
            </div>
            <div className="col-1"></div>
            <div className="col-3"></div>
            <div className="col-1"></div>
          </div>
        </div>}

        <div className='four' style={{ paddingBottom: '100px' }}>
          <h1>The Cyber-Insecurities Most Enterprises Face</h1>
          <div className='d-sm-flex justify-content between pt-3'>
            <p className='px-sm-2 text-center'>Other factors that we witnessed are, enterprises are still using excel to document their accesses, employees and managers are using their own platforms and applications which makes it hard for security leaders to audit - all adding to their vulnerabilities and make it possible for hackers to carryout, cybercrimes.</p>
          </div>
          <Slide />
        </div>

        <div className='four'>
          <h1>How much a cybersecurity <br className='d-sm-block d-none' /> attack costs vs. prevention costs?</h1>
          <div className='content d-md-flex justify-content between pt-5'>
            <p className='px-sm-2 give left-content'>To give our customers an idea on the impact of a breach, a large company will most typically spend between $20 and $50 million per year to resolve a cyber attack. While these figures are far from cheap, we all know that cyberattacks are significantly more expensive. Not only that, but the damage of those attacks to a company's reputation is incalculable.</p>
            <div className='d-block d-md-none imgg text-center'>
              <img src="/cyberattack.png" alt="" />
            </div>
            <p className='px-sm-2 right-content'>On the other hand, the average value of preventing a cyber attack is between $396,000 and $1.37 million--depending on the size of the business and seriousness of the attack. The best ROI of course is in prevention.</p>
            <img src="" alt="" />
            <div className='d-block d-md-none imgg text-center'>
              <img src="/cyberattackn.png" alt="" />
            </div>
          </div>
          <div className='d-none d-md-block text-center pt-5 img text-center'>
            <img src="/c.PNG" alt="" />
          </div>
        </div>

        <div className='four'>
          <h1>Data breaches are the new normal</h1>
          <div className='pt-3'>
            <p className='px-0 px-sm-2 text-center'>Experts around the globe also named data breaches as the new normal since it happens every second. Just last 2020, 37 billion records around the world have been compromised hence the 141% increase compared to 2019.</p>
          </div>
          <div className='d-none d-md-block'>
            <div className="row pt-5 text-center">
              <div className="col-1"></div>
              <div className="col-5"><img src="/breach.svg" alt="" /></div>
              <div className="col-5"><img src="/br.svg" alt="" /></div>
              <div className="col-1"></div>
            </div>
            <div className="row pt-4 text-center">
              <div className="col-1"></div>
              <div className="col-5"><p className='text-center'>With the number of breaches trending up—it is only a matter of time before you’re the next victim.</p></div>
              <div className="col-5"><p className='text-center'>The total number of records compromised in 2020 exceeded 37 billion, a 141% increase compared to 2019.</p></div>
              <div className="col-1"></div>
            </div>
          </div>
          <div className='d-block d-md-none'>
            <div className="row pt-5">
              <div className="col-12 text-center"><img src="/breach.svg" alt="" /></div>
            </div>
            <div className="row pt-5">
              <div className="col-12"><p className='text-center'>With the number of breaches trending up—it is only a matter of time before you’re the next victim.</p></div>
            </div>
            <div className="row pt-5">
              <div className="col-12 text-center"><img src="/br.svg" alt="" /></div>
            </div>
            <div className="row pt-5">
              <div className="col-12"><p className='text-center'>The total number of records compromised in 2020 exceeded 37 billion, a 141% increase compared to 2019.</p></div>
            </div>
          </div>
        </div>

        <div className='four'>
          <h1>Exposure points have exploded</h1>
          <div className='pt-3'>
            <p className='px-sm-2 text-center'>Since enterprises around the world cannot keep up with the right cybersecurity measures to support their new business models, they have generated a lot of exposure points. From building e-commerce stores, employees giving less care on security measures due to cyber fatigue, and vulnerability of remote workers navigating business transactions outside the premise of their offices.</p>
          </div>
          <div className='d-none d-md-block pt-5'>
            <div className="row pt-5">
              <div className="col-1"></div>
              <div className="col-4"><img src="/a.svg" alt="" /></div>
              <div className="col-2"><img src="/b.svg" alt="" /></div>
              <div className="col-2"></div>
              <div className="col-3"><img src="/c.svg" alt="" /></div>
            </div>
            <div className="row pt-4">
              <div className="col-4"><p className='text-center'>Cyber fatigue grips companies whose teams have given up on proactively defending against attacks.</p></div>
              <div className="col-4"><p className='text-center'>Cybercrime has many new victims, as the number of global internet users increases each year.</p></div>
              <div className="col-4"><p className='text-center'>Remote workers will continue to be a target for cybercriminals.</p></div>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none pt-5">
          <div className="row text-center">
            <div className="col-2"></div>
            <div className="col-8"><img src="/a.svg" alt="" /><p className='py-2 para text-center'>Cyber fatigue grips companies whose teams have given up on proactively defending against attacks.</p></div>
            <div className="col-2"></div>
          </div>
          <div className="row text-center pt-5">
            <div className="col-2"></div>
            <div className="col-8"><img src="/b.svg" alt="" /><p className='py-2 para text-center'>Cybercrime has many new victims, as the number of global internet users increases each year.</p></div>
            <div className="col-2"></div>
          </div>
          <div className="row text-center pt-5">
            <div className="col-2"></div>
            <div className="col-8"><img src="/c.svg" alt="" /><p className='py-2 para text-center'>Remote workers will continue to be a target for cybercriminals.</p></div>
            <div className="col-2"></div>
          </div>
        </div>

        <div className='four'>
          <h1>What you're up against</h1>
          <div className='pt-3'>
            <p className='px-sm-2 text-center'>Breakdown of cybercrime damages in USD globally</p>
          </div>
          <div className="graph p-5" style={{ margin: 'auto', width: '100%' }}>
            <img src="/graph.svg" alt="" />
          </div>
          <Swiper
            slidesPerView={3}
            loop={true}
            autoplay={{ delay: 5000 }}
            className="d-md-block text-center whats-up-slider"
          >
            <SwiperSlide><img src="/aa.png" className='slidesagain' alt="" /></SwiperSlide>
            <SwiperSlide><img src="/ab.png" className='slidesagain' alt="" /></SwiperSlide>
            <SwiperSlide><img src="/ac.png" className='slidesagain' alt="" /></SwiperSlide>
            <SwiperSlide><img src="/ad.png" className='slidesagain' alt="" /></SwiperSlide>
            <SwiperSlide><img src="/ae.png" className='slidesagain' alt="" /></SwiperSlide>
            <SwiperSlide><img src="/af.png" className='slidesagain' alt="" /></SwiperSlide>
            <SwiperSlide><img src="/ag.png" className='slidesagain' alt="" /></SwiperSlide>
          </Swiper>
          {/* <Slides /> */}
        </div>

        <div className='four'>
          <h1>Why Cyber Criminals Love Small and Medium-sized <br /> Financial Institutions</h1>
          <div className='d-none d-md-block text-center'>
            <div className="row pt-5">
              <div className="col-1"></div>
              <div className="col-5"><img src="/s.svg" alt="" /></div>
              <div className="col-5"><img src="/t.svg" alt="" /></div>
              <div className="col-1"></div>
            </div>
            <div className="row pt-4">
              <div className="col-1"></div>
              <div className="col-5"><p className='text-center'>They use spreadsheets and manual paper based processes that amplify the security exposure risks</p></div>
              <div className="col-5"><p className='text-center'>Hackers don’t need powerful tools to attack SMB financial businesses</p></div>
              <div className="col-1"></div>
            </div>
            <div className='d-none d-md-block text-center'>
              <div className="row pt-5">
                <div className="col-1"></div>
                <div className="col-5"><img src="/x.svg" alt="" /></div>
                <div className="col-5"><img src="/y.svg" alt="" /></div>
                <div className="col-1"></div>
              </div>
              <div className="row pt-4">
                <div className="col-1"></div>
                <div className="col-5"><p className='text-center'>Cyber criminals don't need an elaborate plan and won't get hotly pursued</p></div>
                <div className="col-5"><p className='text-center'>Your business is a practical choice for attackers looking for a relatively easy swindle</p></div>
                <div className="col-1"></div>
              </div>
            </div>
          </div>
          <div className='d-block d-md-none'>
            <div className="row pt-5">
              <div className="col-12 text-center"><img src="/s.svg" alt="" /></div>
            </div>
            <div className="row pt-5">
              <div className="col-12 text-center"><p className='text-center'>They use spreadsheets and manual paper based processes that amplify the security exposure risks</p></div>
            </div>
            <div className="row pt-5">
              <div className="col-12 text-center"><img src="/t.svg" alt="" /></div>
            </div>
            <div className="row pt-5">
              <div className="col-12 text-center"><p className='text-center'>Hackers don’t need powerful tools to attack SMB financial businesses</p></div>
            </div>
            <div className="row pt-5">
              <div className="col-12 text-center"><img src="/x.svg" alt="" /></div>
            </div>
            <div className="row pt-5">
              <div className="col-12 text-center"><p className='text-center'>Cyber criminals don't need an elaborate plan and won't get hotly pursued</p></div>
            </div>
            <div className="row pt-5">
              <div className="col-12 text-center"><img src="/y.svg" alt="" /></div>
            </div>
            <div className="row pt-5">
              <div className="col-12 text-center"><p className='text-center'>Your business is a practical choice for attackers looking for a relatively easy swindle</p></div>
            </div>
          </div>
        </div>

        <div className='four'>
          <h1>What Small and Medium-sized Financial Institutions Still Believe</h1>
          <div className='pt-3'>
            <p className='px-sm-2 text-center'>Given those data, SMBs still believe that they will not be targeted by cybercriminals.  But just the past year, 20% of them experienced an attack. Not to mention, the damage reached 1.4M usd and being an SME, that’s a huge impact.But what hurts the most is the fact that these SMEs have not reached their full potential and lost their credibilities already.</p>
          </div>
          <div className='d-none d-md-block pt-5'>
            <div className="row pt-4">
              <div className="col-3"></div>
              <div className="col-3"></div>
              <div className="col-3"></div>
              <div className="col-3"><p className='text-center tile-text'>What hurts the most is losing your credibility</p></div>
            </div>
            <div className="row mb-5" >
              <div className="col-3 text-center"></div>
              <div className="col-3 text-center"></div>
              <div className="col-3 text-center"></div>
              <div className="col-3 text-center line"></div>
            </div>
            <div className="row">
              <div className="col-3"></div>
              <div className="col-3"></div>
              <div className="col-3"><p className='text-center tile-text'>These data breaches are costing more than ever before averaging at $108,000 to $1.4 Million</p></div>
              <div className="col-3"></div>
            </div>
            <div className="row" >
              <div className="col-3 text-center"></div>
              <div className="col-3 text-center"></div>
              <div className="col-3 text-center line"></div>
              <div className="col-3 text-center"></div>
            </div>
            <div className="row">
              <div className="col-3"></div>
              <div className="col-3"><p className='text-center tile-text'>But almost 20% experienced an attack in the past year</p></div>
              <div className="col-3"></div>
              <div className="col-3"></div>
            </div>
            <div className="row" >
              <div className="col-3 text-center"></div>
              <div className="col-3 text-center line"></div>
              <div className="col-3 text-center"></div>
              <div className="col-3 text-center"></div>
            </div>
            <div className="row">
              <div className="col-3"><p className='text-center tile-text'>57% of SMBs believe they won’t be targeted by online criminals</p></div>
              <div className="col-3"></div>
              <div className="col-3"></div>
              <div className="col-3"></div>
            </div>
            <div className="row" >
              <div className="col-3 text-center line"></div>
              <div className="col-3 text-center"></div>
              <div className="col-3 text-center"></div>
              <div className="col-3 text-center"></div>
            </div>
          </div>
          <div id="carouselExampleControls" className="d-block d-md-none carousel slide my-5" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <p className='text-center'>57% of SMBs believe they won't be targeted by online criminals</p>
                <div className="text-center"><img src="/line.svg" alt="" /></div>
              </div>
              <div className="carousel-item">
                <p className='text-center'>But almost 20% experienced an attack in the past year</p>
                <div className="text-center"><img src="/line.svg" alt="" /></div>
              </div>
              <div className="carousel-item">
                <p className='text-center'>These data breaches are costing more than ever before averaging at $108,000 to $1.4 Million</p>
                <div className="text-center"><img src="/line.svg" alt="" /></div>
              </div>
              <div className="carousel-item">
                <p className='text-center'>What hurts the most is losing your credibility</p>
                <div className="text-center"><img src="/line.svg" alt="" /></div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        {/* <div className='two-two'>
          <div className='d-none d-lg-block'>
            <h1>PrimeiD launch</h1>
            <a href="https://survey.blink-digital.com/jfe/form/SV_1UkVdvrMbOCjOtw" target="_blank"><button>Register Now</button></a>
          </div>
          <img className='d-none d-lg-block' src="/prime.svg" alt="" />
          <img className='d-block d-lg-none' src="/img.png" alt="" />
          <div className='d-block d-lg-none my-5 text-center'>
            <h1 className='pb-1'>PrimeiD launch</h1>
            <a href="https://survey.blink-digital.com/jfe/form/SV_1UkVdvrMbOCjOtw" target="_blank"><button>Register Now</button></a>
          </div>
        </div> */}

        {/* <div className="primeID-launch desktop"> 
          <div className="container">
            <div className="content-wrapper">
              <h2>PrimeID Launch</h2>  
              <a href="https://survey.blink-digital.com/jfe/form/SV_1UkVdvrMbOCjOtw" target="_blank"><button>Register Now</button></a>            
            </div>
          </div>
        </div>

        <div className="primeID-launch mobile"> 
          <div className="container">
            <div className="content-wrapper">
              <h2>PrimeID Launch</h2>  
              <a href="https://survey.blink-digital.com/jfe/form/SV_1UkVdvrMbOCjOtw" target="_blank"><button>Register Now</button></a>            
            </div>
          </div>
        </div> */}

      </section>
    </>
  )
}

export default Four