// import React, { useState, useEffect } from 'react'
import './Solution.css'

function Solution() {

  // const [button, setbutton] = useState(false)

  // useEffect(() => {
  //   window.addEventListener('scroll', () => {
  //     if (window.scrollY > 100 && window.innerHeight + window.scrollY > document.body.clientHeight) {
  //       setbutton(true)
  //     }
  //     else {
  //       setbutton(false)
  //     }
  //   })
  // }, [])

  // const scroll = () => {
  //   window.scrollTo({
  //     top: document.documentElement.scrollHeight,
  //     behavior: 'smooth'
  //   })
  // }

  return (
    <>
      <section className='mb-5' id='primeid'>
        <div className='two'>

          {/* {button && (
            <button className='d-none d-lg-block btns' onClick={scroll} style={{ position: 'fixed', bottom: '50px', right: '60px', height: '50px', width: '50px', fontSize: '50px', border: 'none', background: 'none', zIndex: 10 }}><img src="/btn2.png" width={32} className="d-block w-30" alt="..." /></button>
          )}
          {button && (
            <button className='d-block d-lg-none btns' onClick={scroll} style={{ position: 'fixed', bottom: '50px', right: '30px', height: '50px', width: '50px', fontSize: '50px', border: 'none', background: 'none', zIndex: 10 }}><img src="/btn2.png" width={48} className="d-block w-30" alt="..." /></button>
          )} */}

          <div id='solution' className='py-5'><h1>PrimeiD IGA Solution for Small and Medium Businesses</h1></div>
          <br />
          <p className="sub-heading">What's wrong with the world right now is, there is no existing IGA solution available in the APAC market built for small and medium businesses to fight against these cyberattacks which leaves them vulnerable - reason why the launch of PrimeiD marks an important milestone for SMBs.</p>
        </div>
        <div className='d-flex justify-content-between p-5 two-next'>
          <div><img src="/one.svg" alt="" />
            <p className='py-2 text-center'>Robust <br /> IGA framework</p>
          </div>
          <div><img src="/two.svg" alt="" />
            <p className='py-2 text-center'>Highly customizable
            </p></div>
          <div><img src="/three.svg" alt="" />
            <p className='py-2 text-center'>Wide-array  of potential <br /> features and developments</p></div>
          <div><img src="/four.svg" alt="" />
            <p className='py-2 text-center'>Useable OOTB <br />
              user interface</p></div>
          <div><img src="/five.svg" alt="" />
            <p className='py-2 text-center'>Quick set-up time of the base <br /> solution</p></div>
        </div>
        {/* <div className='two-two'>
          <div className='d-none d-lg-block'>
            <h1>PrimeiD launch</h1>
            <a href="https://survey.blink-digital.com/jfe/form/SV_1UkVdvrMbOCjOtw" rel="noreferrer" target="_blank"><button>Register Now</button></a>
          </div>
          <img className='d-none d-lg-block' src="/prime.svg" alt="" />
          <img className='d-block d-lg-none' src="/img.png" alt="" />
          <div className='d-block d-lg-none my-5 text-center'>
            <h1 className='pb-1'>PrimeiD launch</h1>
            <a href="https://survey.blink-digital.com/jfe/form/SV_1UkVdvrMbOCjOtw" rel="noreferrer" target="_blank"><button>Register Now</button></a>
          </div>
        </div> */}
        {/* <div className="primeID-launch desktop"> 
          <div className="container">
            <div className="content-wrapper">
              <h2>PrimeID Launch</h2>  
              <a href="https://survey.blink-digital.com/jfe/form/SV_1UkVdvrMbOCjOtw" target="_blank"><button>Register Now</button></a>            
            </div>
          </div>
        </div>

        <div className="primeID-launch mobile"> 
          <div className="container">
            <div className="content-wrapper">
              <h2>PrimeID Launch</h2>  
              <a href="https://survey.blink-digital.com/jfe/form/SV_1UkVdvrMbOCjOtw" target="_blank"><button>Register Now</button></a>            
            </div>
          </div>
        </div> */}
      </section>
    </>
  )
}

export default Solution