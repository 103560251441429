import React, { useState, useEffect } from 'react'
import './Header.css'
function Header() {
    // var didScroll;
    // var lastScrollTop = 0;
    // var delta = 5;
    // var navbarHeight = $('#nav').outerHeight();
    // // on scroll, let the interval function know the user has scrolled
    // $(window).scroll(function () {
    //     didScroll = true;
    // });
    // // run hasScrolled() and reset didScroll status
    // setInterval(function () {
    //     if (didScroll) {
    //         hasScrolled();
    //         didScroll = false;
    //     }
    // }, 250);

    // function hasScrolled() {
    //     var st = $(this).scrollTop();
    //     if (Math.abs(lastScrollTop - st) <= delta)
    //         return;
    //     if (st > lastScrollTop && st > navbarHeight) {
    //         // Scroll Down
    //         $('#nav').removeClass('nav-down').addClass('nav-up');
    //     } else {
    //         // Scroll Up
    //         // If did not scroll past the document (possible on mac)...
    //         if (st + $(window).height() < $(document).height()) {
    //             $('#nav').removeClass('nav-up').addClass('nav-down');
    //         }
    //     }
    //     lastScrollTop = st;
    // }

    const [show, setshow] = useState(false)
    const [navshow, setnavshow] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    const controlNavbar = () => {
        if (typeof window !== 'undefined') { 
          if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
            setnavshow(true); 
          } else { // if scroll up show the navbar
            setnavshow(false);  
          }
    
          // remember current page location to use in the next move
          setLastScrollY(window.scrollY); 
        }
    };
    
    useEffect(() => {
    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', controlNavbar);

        // cleanup function
        return () => {
        window.removeEventListener('scroll', controlNavbar);
        };
    }
    }, [lastScrollY]);

    return (
        <nav className={`navactive ${navshow && 'navhidden'} navbar navbar-expand-lg pxx`} id='nav'>
            <div className="container-fluid">
                <a href="#" className='showMe'><img src="/logo_mobile.png" alt="" /></a>
                <a href="#" className="navbar-brand hideMe logo" to='/'><img src="/logo.svg" alt="logo" /></a>
                <button className='burger-menu border-0 bg-white' style={{ display: show ? 'none' : 'block' }} onClick={() => setshow(!show)}><img className='d-block d-lg-none cursor' src="/hamburger.svg" alt="ham" /></button>
                <button className='close-btn border-0 bg-white' style={{ display: show ? 'block' : 'none' }} onClick={() => setshow(!show)}><img className='d-block d-lg-none cursor' src="/close.svg" alt="ham" /></button>

                <div className="mobile-side-nav d-lg-none mobile-view px-3" style={{ display: show ? 'block' : 'none' }} id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link" style={{fontSize: '18px', lineHeight: '27px'}} href='/'>Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" style={{fontSize: '18px', lineHeight: '27px'}} href='/#form'>PrimeiD Solution</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" style={{fontSize: '18px', lineHeight: '27px'}} href='/#form'>What is IGA?</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" style={{fontSize: '18px', lineHeight: '27px'}} href='/#form'>Talk to us</a>
                        </li>
                    </ul>
                </div>

                <div className="collapse navbar-collapse d-none d-lg-block header-menu">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 nav-list header-main-menu">
                        <li className="nav-item">
                            <a className="nav-link" href='/'>Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href='/#form'>PrimeiD Solution</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href='/#form'>What is IGA?</a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" href='/#form'>Blogs</a>
                        </li> */}
                    </ul>
                    <a className="talk-btn d-none d-lg-flex" style={{ textDecoration: 'none', height: '48px', alignItems: 'center' }} href='/#form'><img src="/avatar.svg" alt="avatar" /> Talk to us</a>
                </div>
            </div>
        </nav>
    )
}

export default Header