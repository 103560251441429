import React from 'react'
import './Header.css'

function Footer() {
    return (
        <>
            <div className="d-none d-lg-block">
                <div className="row">
                    <div className="col-5">
                        <div className='p-5'>
                            <img src="/logo.svg" alt="" />
                            <p className='py-4' style={{fontSize: '18px'}}>The 1st Identity Governance and Administration (IGA) <br className='d-xl-block d-none' /> Solution Built for Small and Medium Businesses</p>
                            {/* <div className="d-flex">
                                <img className='px-3' src="/w.svg" alt="" />
                                <img className='px-3' src="/f.svg" alt="" />
                                <img className='px-3' src="/i.svg" alt="" />
                            </div> */}
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="d-flex justify-content-between px-5 footer" style={{ paddingTop: '6rem' }}>
                            <a href='/' style={{textDecoration: 'none', color: 'black', fontSize: '18px'}}>Home</a>
                            <a href='/#form' style={{textDecoration: 'none', color: 'black', fontSize: '18px'}}>PrimeiD Solution</a>
                            <a href='/#form' style={{textDecoration: 'none', color: 'black', fontSize: '18px'}}>What is IGA?</a>
                            {/* <a href='/#form' style={{textDecoration: 'none', color: 'black', fontSize: '18px'}}>Blogs</a> */}
                        </div>
                    </div>
                    <div className="col-1"></div>
                </div>
                <div className="text-center py-4">
                    <p style={{fontSize: '18px'}}>© 2022 PrimeiD. All Rights Reserved.</p>
                </div>
            </div>
            <div className="d-block d-lg-none my-5">
                <div className="row py-3"><div className="col-12 text-center"><img src="/logo.svg" alt="" /></div></div>
                <div className="row py-3 px-1"><div className="col-12 text-center"><h4 style={{fontSize: '18px', paddingLeft: '1rem', paddingRight: '1rem'}}>The 1st Identity Governance and Administration (IGA) <br className='d-xl-block d-none' /> Solution Built for Small and Medium Businesses</h4></div></div>
                <div className="row pt-5"><div className="col-12 text-center footer"><a href='/' style={{textDecoration: 'none', color: 'black',fontSize: '18px'}}>Home</a></div></div>
                <div className="row"><div className="col-12 text-center footer"><a href='/#form' style={{textDecoration: 'none', color: 'black',fontSize: '18px'}}>PrimeiD Solution</a></div></div>
                <div className="row"><div className="col-12 text-center footer"><a href='/#form' style={{textDecoration: 'none', color: 'black',fontSize: '18px'}}>What is IGA?</a></div></div>
                {/* <div className="row"><div className="col-12 text-center"><a href='/#form' style={{fontSize: '18px'}}>Blogs</a></div></div> */}
                <div className="row"><div className="col-12 text-center footer"><a href='/#form' style={{textDecoration: 'none', color: 'black',fontSize: '18px'}}>Talk to us</a></div></div>
                {/* <div className="p-5 row"><div className="col-12"><div className="so d-flex ">
                    <img className='px-3' src="/w.svg" alt="" />
                    <img className='px-3' src="/f.svg" alt="" />
                    <img className='px-3' src="/i.svg" alt="" />
                </div></div></div> */}
                <div className="row" style={{paddingTop: '3rem'}}><div className="col-12 text-center"><p>© 2022 PrimeiD. All Rights Reserved.</p></div></div>
            </div>
            <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js"></script>
        </>
    )
}

export default Footer